import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { logError } from '../../../applicationTelemetry';
import {
  Action,
  FilterInput,
  GroupDataFragment,
  Resource,
  useAlertGroupFailedMutation,
  useCreateGroupIncludeDataMutation,
  useGetGroupLazyQuery,
} from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import { SearchPreviewContext } from '../../context/SearchPreviewContext';
import { SearchPreviewDispatchContext } from '../../context/SearchPreviewDispatchContext';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';

export function BuildGroupButton(props: { filterInput: FilterInput }) {
  // const [createGroupMutation] = useCreateGroupMutation({});
  const [alertGroupFailed] = useAlertGroupFailedMutation({});
  const [getGroupFull] = useGetGroupLazyQuery({});

  const { curTeamId: teamId } = useValidTeamAppContext();
  const { excludeFromNewFilter } = useContext(SearchPreviewContext);
  const searchTerm = useContext(SearchPreviewContext).searchTerm;
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const searchPreviewDispatch = useContext(SearchPreviewDispatchContext);
  const [loading, setLoading] = useState(false);

  const [createGroupMutation] = useCreateGroupIncludeDataMutation({});
  const { hasPermission } = useContext(PermissionsContext);
  const canCreateGroups = hasPermission(Resource.Groups, Action.Create);

  const executeSearchQuery = (group: GroupDataFragment) => {
    if (group.isExactMatch) {
      getGroupFull({
        variables: { teamId, groupId: group.id, belongs: true, filterInput: props.filterInput },
        onCompleted: (data) => {
          toast.success(`Successfully created search group for ${data.getGroup.title}`);
          const finalGroup = data.getGroup;
          taxonomyDispatch({
            type: 'addGroup',
            payload: { group: finalGroup },
          });
          searchPreviewDispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
          searchPreviewDispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
        },
      });
    } else if (group.groupEntries?.every((entry) => !entry.belongsToGroup)) {
      searchPreviewDispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
      searchPreviewDispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
      toast.error('No sentences found for this search. Please try a different search.');
    } else {
      searchPreviewDispatch({ type: 'createGroup', payload: { group: group } });
    }
  };

  return (
    <div className={'mb-2'}>
      <Button
        data-testid="build-new-group"
        disabled={loading || !canCreateGroups}
        id={'build-new-group'}
        variant={ButtonVariant.Primary}
        disabledTooltip={!canCreateGroups ? "You do not have permission to create groups. Please contact your administrator and request the Contributor role." : undefined}
        text={'Didn’t find what you want? Build a new Group'}
        loadingText={'Building Group Preview....'}
        loadingConfirm={loading}
        className="text-sm mb-2"
        onClick={async () => {
          setLoading(true);
          await createGroupMutation({
            variables: {
              teamId,
              queryString: searchTerm,
              excludeFromNewFilter,
            },
            onError: async (error) => {
              const permissionError = error?.graphQLErrors.find((graphQLError) => graphQLError.extensions?.code === 'UNAUTHORIZED');
              if(permissionError) return;

              await alertGroupFailed({ variables: { teamId, queryString: searchTerm, wasRetried: false } });
              toast.error("We're sorry, we are experiencing temporary issues with Group building. We are actively working on a fix.");
              logError(`Error: ${error.name}, Message: ${error.message}`);
            },
            onCompleted: (data) => {
              if (data?.createGroup) {
                executeSearchQuery(data.createGroup);
              }
            },
          });
          setLoading(false);
        }}
      />
    </div>
  );
}

import { useMutation } from '@apollo/client';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Action, ApproveGroupDocument, GroupEntryFragment, Resource, SentenceEntryFragment } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import Modal from '../../baseComponents/Modal';
import { SearchPreviewContext } from '../../context/SearchPreviewContext';
import { SearchPreviewDispatchContext } from '../../context/SearchPreviewDispatchContext';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import { ExplorePageView } from '../../hooks/ExploreGroupHook';
import SearchResult from './SearchResult';

const SearchPreviewModal = ({ setView }: { setView: Dispatch<SetStateAction<ExplorePageView>> }) => {
  const [approveGroup] = useMutation(ApproveGroupDocument);

  const [loadingApprovedGroup, setLoadingApprovedGroup] = useState(false);

  const { curTeamId: teamId } = useValidTeamAppContext();
  const previewDispatch = useContext(SearchPreviewDispatchContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const { group, belongingIds, excludedIds, excludeFromNewFilter } = useContext(SearchPreviewContext);

  const [open, setOpen] = useState<boolean>(group.id != '0');
  const { hasPermission } = useContext(PermissionsContext);
  const canCreateGroups = hasPermission(Resource.Groups, Action.Create);

  useEffect(() => {
    if (group.id != '0') {
      setOpen(true);
    }
  }, [group]);

  const clearSearch = () => {
    previewDispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
    previewDispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
  };

  const removeEntry = (entryId: string) => {
    previewDispatch({
      type: 'removeEntry',
      payload: {
        id: entryId,
      },
    });
  };
  const addEntry = (entryId: string) => {
    previewDispatch({
      type: 'addEntry',
      payload: {
        id: entryId,
      },
    });
  };

  const approveSearchGroup = async () => {
    if (!belongingIds.length) {
      toast.error('There must be at least one sentence in the search group');
      return;
    }
    if (!group.id) {
      toast.error('Unknown Error');
      return;
    }
    await approveGroup({
      variables: {
        teamId,
        groupId: group.id,
        belongingSentenceIds: belongingIds,
        differingSentenceIds: excludedIds,
      },

      onCompleted(data) {
        const group = data.approveGroup;
        taxonomyDispatch({
          type: 'buildGroup',
          payload: {
            group,
          },
        });
        toast.success('Search group is now building...');
        previewDispatch({ type: 'clearGroup', payload: {} });
        setOpen(false);
      },
    });
  };

  // when we close the modal, we need to clear the current search, current group, and reset taxonomy
  const closeModal = () => {
    previewDispatch({
      type: 'clearGroup',
      payload: {},
    });
    clearSearch();
    setOpen(false);
    setView(ExplorePageView.Taxonomy);
  };

  return (
    <Modal
      open={open}
      setOpen={(open) => {
        setOpen(open);
        !open && closeModal();
      }}
      width="w-[50rem]"
    >
      <div className="text-blueberry ">
        <div className={`bg-blueberry text-milk rounded-t-xl p-4 absolute top-0 left-0 w-full`}>
          <h2 className="text-2xl mb-2 t-6 font-bold">Create Group for "{group.title}"</h2>
          <div className="flex flex-row mb-2">
            <p>Below is a preview of what your new Group will include</p>
            <Tippy
              content={
                'Remove examples from this preview if you don’t want them included in your new Group. This will teach our system to exclude similar examples from the Group.'
              }
            >
              <InformationCircleIcon className="w-5 h-5 stroke-2 ml-1" />
            </Tippy>
          </div>
        </div>
        <div className="mt-24 gap-y-2 flex-grow overflow-y-scroll h-[90%] pt-4 border-b-gray-100 divide-y divide-silver-darker">
          {group.groupEntries
            ?.filter(
              (entry): entry is GroupEntryFragment & { mappedSentences: SentenceEntryFragment[] } =>
                entry.belongsToGroup && entry.mappedSentences.length > 0 && entry.mappedSentences[0].entry != null
            )
            .slice(0, 10)
            .map((entry) => {
              const entryId = entry.mappedSentences[0].entry.id;
              return (
                <SearchResult key={entryId} entry={entry} removeEntry={removeEntry} addEntry={addEntry} isExcluded={excludedIds.includes(entryId || '-1')} />
              );
            }) || (
            <div className="flex flex-row justify-center items-center">
              <LoadingSpinner />
            </div>
          )}
        </div>

        <div className="mt-4 flex flex-row justify-between gap-x-4 text-center">
          <Button variant={ButtonVariant.Tertiary} onClick={closeModal} text="Cancel" />

          <Button
            variant={ButtonVariant.Primary}
            text="Create This Group"
            onClick={async () => {
              setLoadingApprovedGroup(true);
              setView(ExplorePageView.Taxonomy);
              await approveSearchGroup();
              setLoadingApprovedGroup(false);
            }}
            loadingConfirm={loadingApprovedGroup}
            disabled={!canCreateGroups}
            disabledTooltip="You do not have permission to create groups. Please contact your administrator and request the Contributor role."
          />
        </div>
      </div>
    </Modal>
  );
};
export default SearchPreviewModal;

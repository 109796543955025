import { Creator } from '../../../../v2/hooks/GroupHook';

interface CreatedByProps {
  creator: Creator;
  dateCreated: number;
}

const CreatedBy = ({ creator, dateCreated }: CreatedByProps) => {
  // If we don't have a creator email, we assume it's unwrap generated
  const isUnwrapGenerated = creator.isUnwrapGenerated || !creator.creatorEmail;
  const localDateCreated = new Date(dateCreated).toLocaleDateString();
  return (
    <div className="flex flex-row items-center gap-x-1 flex-wrap">
      <div className="text-gray-400">Created by</div>
      <div className="break-all">{isUnwrapGenerated ? 'Unwrap.ai' : creator.creatorEmail}</div>
      <div className="text-gray-400">on</div>
      <div>{localDateCreated}</div>
    </div>
  );
};

export default CreatedBy;
